import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';
import { Color } from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';

interface ThreeDViewerProps {
    objFile: string;
}

const ThreeDViewer: React.FC<ThreeDViewerProps> = ({ objFile }) => {
    const mountRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!mountRef.current) return;

        // Create a new scene
        const scene = new THREE.Scene();
        scene.background = new Color('white');

        // Create a new camera
        const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
        camera.position.z = 5;

        // Create a new renderer
        const renderer = new THREE.WebGLRenderer();
        renderer.setSize(window.innerWidth / 4 * 3, window.innerHeight / 4 * 3);
        mountRef.current.appendChild(renderer.domElement);

        // Add orbit controls
        const controls = new OrbitControls(camera, renderer.domElement);

        // Add lighting
        const ambientLight = new THREE.AmbientLight(0xffffff);
        scene.add(ambientLight);

        // Load the OBJ file
        const objLoader = new OBJLoader();
        let model: THREE.Object3D | null = null;
        objLoader.load(
            objFile,
            (object) => {
                model = object;
                scene.add(object);
            },
            undefined,
            (error) => {
                console.error('An error occurred while loading the OBJ file:', error);
            }
        );

        // Render the scene
        const animate = () => {
            requestAnimationFrame(animate);
            controls.update();

            // Rotate the model
            if (model) {
                model.rotation.x += 0.005;
                model.rotation.y += 0.005;
            }
            renderer.render(scene, camera);
        };
        animate();

        // Clean up on unmount
        return () => {
            mountRef.current?.removeChild(renderer.domElement);
        };
    }, [objFile]);

    return (
        <div
            ref={mountRef}
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '75vh',
            }}
        />
    );
};

export default ThreeDViewer;
