import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { Container, Nav, Navbar } from "react-bootstrap"

const logoImg = require('../assets/images/logo.png');

export const Header = () => {
    return <header className="app-header">
        <Navbar expand="lg" className="bg-body-tertiary">
            <Container>
                <Navbar.Brand>
                    <img src={logoImg} alt="Logo" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll">
                    <Nav className="me-auto my-2 my-lg-0" navbarScroll>
                        {/*<Nav.Link href="#whitelist">Whitelist</Nav.Link>*/}
                    </Nav>
                    <div className="d-flex">
                        <WalletMultiButton />
                    </div>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    </header >
}

export const Footer = () => {
    return <footer className="app-footer mt-auto text-center h-25 text-muted">
        &copy; 2024 EduBloc
    </footer>
}