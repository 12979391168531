import './styles/scss/global.scss';
import Content from './components/Content';
import { Footer, Header } from './components/Layout';
import ViewerTest from './components/3dtest';

const model = require('./assets/models/block.obj');

function App() {
  return (
    <div className="App">
      <Header />
      <ViewerTest objFile={model} />
      <Footer />
    </div>
  );
}

export default App;
